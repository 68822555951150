import getStepLabelTemplateService
  from '~/src/components/mui-wrappers/styled/step-label/styles/GetStepLabelTemplateService'
import StylesStepLabelServiceInterface
  from '~/src/components/mui-wrappers/styled/step-label/styles/StylesStepLabelServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetStepLabelTemplate = (): StylesStepLabelServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getStepLabelTemplateService(pageStyle, muiTheme)
}

export default useGetStepLabelTemplate
