import { OrderSummary } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { StatusStep } from '~/src/types/StatusStep'
import { useMemo } from 'react'
import OrderStatusService from '~/src/services/OrderStatusService'

const useOrderStatus = (order: OrderSummary): StatusStep[] => {
  const firm = useEoValue(FIRM_SELECTOR)
  const orderStatusService = new OrderStatusService(order)

  return useMemo(() => {
    if (!firm) {
      return []
    }

    return orderStatusService.convertStatusesToSteps(firm.settings.theme?.flow?.ordering.orderStatuses || [], [ 'HANDLED' ])
  }, [ order.status ])
}

export default useOrderStatus
