import { Typography } from '@mui/material'
import StepLabel from '~/src/components/mui-wrappers/styled/step-label'
import { StatusStep } from '~/src/types/StatusStep'
import StepLabelIcon from '~/src/components/mui-wrappers/styled/StepLabelIcon'
import Title from '~/src/components/mui-wrappers/Title'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { OrderStatusType } from '@eo-storefronts/eo-core'
import { ReactNode } from 'react'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined'
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  step: StatusStep,
}

const OrderStatusBarStepLabel = ({ step }: Props) => {
  const theme = useCustomMuiTheme()
  const { t } = useTranslations()

  const _getStepIcon = (orderStatus: OrderStatusType): ReactNode => {
    switch (orderStatus) {
      case 'NEW':
        return <ReceiptLongOutlinedIcon />
      case 'HANDLED':
        return <HomeOutlinedIcon />
      case 'PLANNED':
        return <CalendarMonthOutlinedIcon />
      case 'PREPARING':
        return <RestaurantOutlinedIcon />
      case 'READY':
        return <ShoppingBasketOutlinedIcon />
      case 'CANCELLED':
        return <CancelIcon />
      case 'IN_DELIVERY':
        return <DeliveryDiningIcon />
    }
  }

  return (
    <StepLabel
      error={step.type === 'CANCELLED'}
      StepIconComponent={StepLabelIcon}
      icon={_getStepIcon(step.type)}
      sx={{
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
          height: '130px'
        },
        [theme.breakpoints.down('sm')]: {
          height: '100px'
        }
      }}
    >
      <Title variant='h6'>
        {t(step.label)}
      </Title>
      <Typography variant='body1'>
        {t(step.subLabel)}
      </Typography>
    </StepLabel>
  )
}

export default OrderStatusBarStepLabel
