import StylesStepConnectorServiceInterface
  from '~/src/components/mui-wrappers/styled/step-connector/styles/StylesStepConnectorServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import { stepConnectorClasses } from '@mui/material'

export default class StylesStepConnectorServiceStyleOne extends TemplateServiceStyleBase implements StylesStepConnectorServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      [`&>.${stepConnectorClasses.line}`]: {
        border: 'none'
      },
      [`&.${stepConnectorClasses.horizontal}`]: {
        top: '14px',
        right: '100%',
        left: 'unset',
        width: '100%',
        paddingLeft: '45px'
      },
      [`&>.${stepConnectorClasses.lineHorizontal}`]: {
        [`&.${stepConnectorClasses.line}`]: {
          borderTop: '4px dotted',
          color: this.muiTheme.palette.text.secondary
        }
      },
      [`&.${stepConnectorClasses.vertical}`]: {
        top: '-100%',
        right: 'unset',
        left: '1px',
        height: '100%',
        paddingTop: '50px'
      },
      [`&>.${stepConnectorClasses.lineVertical}`]: {
        [`&.${stepConnectorClasses.line}`]: {
          borderLeft: '4px dotted',
          color: this.muiTheme.palette.text.secondary,
          height: '100%'
        }
      },
      [`&.${stepConnectorClasses.active}`]: {
        [`&>.${stepConnectorClasses.line}`]: {
          borderColor: this.muiTheme.palette.primary.main
        }
      },
      [`&.${stepConnectorClasses.completed}`]: {
        [`&>.${stepConnectorClasses.line}`]: {
          borderColor: this.muiTheme.palette.primary.main
        }
      }
    }
  }
}
