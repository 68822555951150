import getStepConnectorTemplateService
  from '~/src/components/mui-wrappers/styled/step-connector/styles/GetStepConnectorTemplateService'
import StylesStepConnectorServiceInterface
  from '~/src/components/mui-wrappers/styled/step-connector/styles/StylesStepConnectorServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetStepConnectorTemplate = (): StylesStepConnectorServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getStepConnectorTemplateService(pageStyle, muiTheme)
}

export default useGetStepConnectorTemplate
