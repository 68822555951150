import { OrderDetail } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import OrderProductListItem from '~/src/components/orders/OrderProductListItem'

interface Props {
  orderDetails: OrderDetail[],
}

const OrderProductList = ({ orderDetails }: Props) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: 2
    }}>
      { orderDetails.map((orderDetail: OrderDetail) => (
        <OrderProductListItem key={orderDetail.id} order={orderDetail} />
      ))}
    </Box>
  )
}

export default OrderProductList
