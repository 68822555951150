import { OrderStatusType, OrderSummary } from '@eo-storefronts/eo-core'
import { StatusStep } from '~/src/types/StatusStep'

interface GeneratedLabelAndSubLabel {
  label: string,
  subLabel: string,
}

export default class OrderStatusService {
  private readonly _order: OrderSummary

  public constructor(order: OrderSummary) {
    this._order = order
  }

  public convertStatusesToSteps(statuses: string[], useSpecificTranslationOn: OrderStatusType[] = []): StatusStep[] {
    const statusesToConvert = this._getStatusToConvert(statuses)
    const activeStatusIndex = statusesToConvert.findIndex((status: string) => status.toUpperCase() === this._order.status)

    return statusesToConvert.map((status: string, index: number) => (
      this._createStep(status, useSpecificTranslationOn, activeStatusIndex, index)
    ))
  }

  private _createStep(status: string, useSpecificTranslationOn: OrderStatusType[], activeStatusIndex: number, currentIndex: number): StatusStep {
    const statusType: OrderStatusType = status.toUpperCase() as OrderStatusType
    const { label, subLabel } = this._getStepLabelAndSubLabel(statusType, useSpecificTranslationOn)

    return {
      label,
      subLabel,
      isActive: this._isStatusActive(statusType, activeStatusIndex, currentIndex),
      type: statusType
    }
  }

  private _getStatusToConvert(statuses: string[]): string[] {
    // Cancel status should only be displayed when order is cancelled
    if (this._order.status !== 'CANCELLED') {
      statuses = statuses.filter(status => status !== 'cancelled')
    }

    // In_delivery status should only be displayed when order delivery method is delivery
    if (this._order.delivery_method !== 'delivery') {
      statuses = statuses.filter(status => status !== 'in_delivery')
    }

    return statuses
  }

  private _isStatusActive(status: OrderStatusType, activeStatusIndex: number, currentIndex: number): boolean {
    // Status does not exist
    if (activeStatusIndex === -1) {
      return false
    }

    // Order is cancelled so just CANCELLED id active
    if (this._order.status === 'CANCELLED') {
      return status === 'CANCELLED'
    }

    // By default, all status before the active status are active too
    return currentIndex <= activeStatusIndex
  }

  private _getStepLabelAndSubLabel(status: OrderStatusType, useSpecificTranslationOn: OrderStatusType[]): GeneratedLabelAndSubLabel {
    let label = `order_status.${status.toLowerCase()}`
    let subLabel = `order_status.${status.toLowerCase()}_subLabel`

    // If no status are specified in useSpecificTranslationOn all status will have a specific translation by delivery method
    // If one or many status are specified only these status will have a specific translation
    if (useSpecificTranslationOn.length === 0 || useSpecificTranslationOn.includes(status)) {
      label = `${label}.${this._order.delivery_method}`
      subLabel = `${subLabel}.${this._order.delivery_method}`
    }

    return {
      label,
      subLabel
    }
  }
}
