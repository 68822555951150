import { styled } from '@mui/material'

interface Props {
  ownerState: {
    completed?: boolean,
    active?: boolean,
    error?: boolean,
  },
}

const StepLabelIconContainer = styled('div')<Props>(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.completed && !ownerState.error && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }),
  ...(ownerState.error && {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  })
}))

export default StepLabelIconContainer

