import { Typography } from '@mui/material'
import { DateUtils } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { TypographyProps } from '@mui/material/Typography/Typography'
import TodayDateLabel from '~/src/components/TodayDateLabel'

interface Props extends TypographyProps {
  date: Date,
}

const DateLabel = ({ date, variant = 'body2', ...otherProps }: Props) => {
  const lang = useEoValue(LANGUAGE_STATE)

  if (DateUtils.isSameDate(date, new Date(), 'day')) {
    return <TodayDateLabel variant={variant} { ...otherProps } />
  }

  return (
    <Typography variant={variant} { ...otherProps }>
      {DateUtils.format(date, lang)}
    </Typography>
  )
}

export default DateLabel
