import StylesStepLabelServiceInterface
  from '~/src/components/mui-wrappers/styled/step-label/styles/StylesStepLabelServiceInterface'
import StylesStepLabelServiceStyleOne
  from '~/src/components/mui-wrappers/styled/step-label/styles/StylesStepLabelServiceStyleOne'
import StylesStepLabelServiceStyleTwo
  from '~/src/components/mui-wrappers/styled/step-label/styles/StylesStepLabelServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getStepLabelTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesStepLabelServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesStepLabelServiceStyleTwo(muiTheme)
    default:
      return new StylesStepLabelServiceStyleOne(muiTheme)
  }
}

export default getStepLabelTemplateService
