import Title from '~/src/components/mui-wrappers/Title'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'

const AwaitingForPayment = () => {
  const { t } = useTranslations()

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: 2,
      p: 2
    }}>
      <Box>
        <CircularProgress size='1.5rem' thickness={6} />
      </Box>

      <Box>
        <Title variant='h5'>{t('order_status.awaiting_for_payment.title')}</Title>
        <Typography>{t('order_status.awaiting_for_payment.sub_title')}</Typography>
      </Box>
    </Box>
  )
}

export default AwaitingForPayment
