import { Typography } from '@mui/material'
import { TypographyProps } from '@mui/material/Typography/Typography'
import { useTranslations } from '~/src/hooks/useTranslations'

const TodayDateLabel = ({ variant = 'body2', ...otherProps }: TypographyProps) => {
  const { t } = useTranslations()
  
  return (
    <Typography variant={variant} { ...otherProps }>
      { t('global.today') }
    </Typography>
  )
}

export default TodayDateLabel
