import { Product } from '@eo-storefronts/eo-core'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  product?: Product,
}

const OrderProductName = ({ product }: Props) => {
  const { t } = useTranslations()

  return (
    <strong>
      {product?.name && product.name as unknown as string}
      {!product?.name && t('product.unknown')}
    </strong>
  )
}

export default OrderProductName
