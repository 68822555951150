import { OrderSummary } from '@eo-storefronts/eo-core'
import Card from '~/src/components/mui-wrappers/Card'
import OrderStatusBar from '~/src/components/orders/order-status/OrderStatusBar'
import AwaitingForPayment from '~/src/components/orders/order-status/AwaitingForPayment'
import { CardProps } from '@mui/material'

interface Props extends CardProps {
  orderSummary: OrderSummary,
}

const OrderStatus = ({ orderSummary, sx = {}, elevation = 0, ...cardProps }: Props) => {
  const _isAwaitingForPayment = (): boolean => {
    if (orderSummary.payment_method.id === 1 || orderSummary.status === 'CANCELLED') {
      return false
    }

    return !orderSummary.paid
  }

  return (
    <Card
      sx={{
        p: 0,
        borderRadius: '1em',
        ...sx
      }}
      elevation={elevation}
      {...cardProps}>
      {_isAwaitingForPayment() ? <AwaitingForPayment /> : <OrderStatusBar orderSummary={orderSummary} />}
    </Card>
  )
}

export default OrderStatus
