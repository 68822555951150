import { Firm } from '@eo-storefronts/eo-core'
import { Paper, Typography } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import FirmLocationMap from '~/src/components/firms/FirmLocationMap'
import FirmContact from '~/src/components/firms/FirmContact'

interface Props {
  firm: Firm,
}

const FirmInfoCard = ({ firm }: Props) => (
  <Paper
    elevation={0}
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      bgcolor: 'secondary.main',
      color: 'secondary.contrastText',
      borderRadius: '1em'
    }}>
    <Title variant='h6'>
      {firm.name}
    </Title>
    {(firm?.address?.address && firm?.address?.zipcode && firm?.address?.locality) && (
      <Typography variant='body1'>
        {`${firm?.address.address} ${firm?.address.zipcode} ${firm?.address.locality}`}
      </Typography>
    )}
    <FirmLocationMap firm={firm}/>
    <FirmContact firm={firm} />
  </Paper>
)

export default FirmInfoCard
