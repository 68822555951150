import { Customer, Firm, Id, OrderSummary } from '@eo-storefronts/eo-core'
import { useCallback, useState } from 'react'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { fetchOrder } from '~/src/services/OrderService'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { ORDER_DETAILS_STATE } from '~/src/stores/orders'

interface ReturnsType {
  fetch(orderId?: Id | null, customer?: Customer | null, updateLoaderState?: boolean): Promise<void>,
  loading: boolean,
}

export const useFetchOrderDetail = (): ReturnsType => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const { loggedInAs } = useEoValue(AUTHENTICATION_STATE)
  const setOrderDetail = useSetEoState(ORDER_DETAILS_STATE)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)

  const _getTempCustomerId = (customer?: Customer | null): Id | undefined => {
    if (!loggedInAs) {
      return
    }

    if (loggedInAs === 'customer') {
      return customer?.id
    }

    return 1
  }

  const fetch = useCallback(async (orderId?: Id | null, customer?: Customer | null, updateLoaderState = true): Promise<void> => {
    const customerId: Id | undefined = _getTempCustomerId(customer)

    if (!firm || !orderId || !customerId) {
      setOrderDetail(null)
      updateLoaderState && setLoading(false)

      return Promise.resolve()
    }

    updateLoaderState && setLoading(true)

    return fetchOrder(orderId,
      firm.id,
      firm.uuid || '',
      customerId,
      customer?.uuid || ''
    )
      .then((order: unknown) => {
        setOrderDetail(order as OrderSummary)
      })
      .catch(() => {
        // Nothing
      })
      .finally(() => {
        updateLoaderState && setLoading(false)
      })
  }, [])

  return { fetch, loading }
}

