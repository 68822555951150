import { OrderSummary } from '@eo-storefronts/eo-core'
import { Box, BoxProps, Typography } from '@mui/material'
import OrderProductList from '~/src/components/orders/OrderProductList'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props extends BoxProps<'div'> {
  orderSummary: OrderSummary,
}

const OrderProductDetails = ({ orderSummary, ...boxProps }: Props) => {
  const { t } = useTranslations()

  return (
    <Box {...boxProps}>
      <Typography variant='body1'>
        {t('orders.order_detail')}
      </Typography>
      <OrderProductList orderDetails={orderSummary.order_details} />
    </Box>
  )
}

export default OrderProductDetails
