import { OrderSummary } from '@eo-storefronts/eo-core'
import { Step, stepConnectorClasses, Stepper, Theme, useMediaQuery } from '@mui/material'
import { StatusStep } from '~/src/types/StatusStep'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import StepConnector from '~/src/components/mui-wrappers/styled/step-connector'
import OrderStatusBarStepLabel from '~/src/components/orders/order-status/OrderStatusBarStepLabel'
import useOrderStatus from '~/src/hooks/orders/useOrderStatus'

interface Props {
  orderSummary: OrderSummary,
}

const OrderStatusBar = ({ orderSummary }: Props) => {
  const muiTheme = useCustomMuiTheme()
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const steps = useOrderStatus(orderSummary)

  return (
    <Stepper
      alternativeLabel
      connector={<StepConnector />}
      orientation={isSmallDevice ? 'vertical' : 'horizontal'}
      sx={{
        px: 8,
        py: 5,
        [muiTheme.breakpoints.down('lg')]: {
          px: 4,
          py: 2
        },
        [muiTheme.breakpoints.down('md')]: {
          p: 2
        }
      }}
    >
      {steps.map((step: StatusStep) => (
        <Step
          key={step.label}
          completed={step.isActive}
          sx={{
            [`& > .${stepConnectorClasses.root}`]: {
              display: step.type === 'CANCELLED' ? 'none' : 'inherit'
            }
          }}
        >
          <OrderStatusBarStepLabel step={step} />
        </Step>
      ))}
    </Stepper>
  )
}

export default OrderStatusBar
