import { Box, BoxProps } from '@mui/material'
import { Firm } from '@eo-storefronts/eo-core'
import EnvUtils from '~/src/utils/EnvUtils'

interface Props extends BoxProps {
  firm: Firm,
}

const FirmLocationMap = ({ firm, ...otherProps }: Props) => (
  <Box
    sx={{
      border: 'none',
      width: '100%',
      height: 'auto'
    }}
    {...otherProps}
    component='iframe'
    loading='lazy'
    referrerPolicy='no-referrer-when-downgrade'
    src={`https://www.google.com/maps/embed/v1/place?key=${EnvUtils.googleMapKey}&q=${firm.address.address}, ${firm.address.zipcode} ${firm.address.locality}`}
  />
)

export default FirmLocationMap
