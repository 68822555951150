import { OrderSummary } from '@eo-storefronts/eo-core'
import OrderGetInvoiceButton from '~/src/components/orders/OrderGetInvoiceButton'

interface Props {
  orderSummary: OrderSummary,
}

const OrderDetailActions = ({ orderSummary }: Props) => {
  return (
    <>
      <OrderGetInvoiceButton orderSummary={orderSummary} />
    </>
  )
}

export default OrderDetailActions
