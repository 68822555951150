import { OrderDetail } from '@eo-storefronts/eo-core'
import { Typography } from '@mui/material'
import OrderProductName from '~/src/components/orders/OrderProductName'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  order: OrderDetail,
}

const OrderProductListItem = ({ order }: Props) => {
  const { t } = useTranslations()

  return (
    <Typography variant='body1'>
      {order.quantity}x <OrderProductName product={order.product} />
      {order.is_coupon && <span> ({t('shopping_cart.coupon_discount')})</span>}
      {order.is_loyalty && <span> ({t('shopping_cart.loyalty_discount')})</span>}
    </Typography>
  )
}

export default OrderProductListItem
