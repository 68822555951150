import { StepIconProps } from '@mui/material/StepIcon'
import StepLabelIconContainer from '~/src/components/mui-wrappers/styled/StepLabelIconContainer'
import Check from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'

const StepLabelIcon = ({ active, completed, error, className, icon }: StepIconProps) => {
  return (
    <StepLabelIconContainer
      ownerState={{ completed, active, error }}
      className={className}
    >
      {completed && !error && <Check />}
      {error && <ClearIcon />}
      {!completed && !error && icon}
    </StepLabelIconContainer>
  )
}

export default StepLabelIcon
