import StylesStepConnectorServiceInterface
  from '~/src/components/mui-wrappers/styled/step-connector/styles/StylesStepConnectorServiceInterface'
import StylesStepConnectorServiceStyleOne
  from '~/src/components/mui-wrappers/styled/step-connector/styles/StylesStepConnectorServiceStyleOne'
import StylesStepConnectorServiceStyleTwo
  from '~/src/components/mui-wrappers/styled/step-connector/styles/StylesStepConnectorServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getStepConnectorTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesStepConnectorServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesStepConnectorServiceStyleTwo(muiTheme)
    default:
      return new StylesStepConnectorServiceStyleOne(muiTheme)
  }
}

export default getStepConnectorTemplateService
