import { stepLabelClasses } from '@mui/material'
import StylesStepLabelServiceInterface
  from '~/src/components/mui-wrappers/styled/step-label/styles/StylesStepLabelServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesStepLabelServiceStyleOne extends TemplateServiceStyleBase implements StylesStepLabelServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      [`&.${stepLabelClasses.vertical}`]:{
        [`&.${stepLabelClasses.alternativeLabel}`]: {
          flexDirection: 'row'
        },
        [`&>.${stepLabelClasses.labelContainer}`]: {
          [`&>.${stepLabelClasses.label}`]: {
            [`&.${stepLabelClasses.alternativeLabel}`]: {
              marginTop: 0,
              marginBottom: this.muiTheme.spacing(2),
              marginLeft: this.muiTheme.spacing(2)
            }
          }
        }
      },
      [`&.${stepLabelClasses.horizontal}`]:{
        [`&>.${stepLabelClasses.labelContainer}`]: {
          [`&>.${stepLabelClasses.label}`]: {
            [`&.${stepLabelClasses.alternativeLabel}`]: {
              marginTop: this.muiTheme.spacing(2),
              marginBottom: this.muiTheme.spacing(2)
            }
          }
        }
      },
      [`&>.${stepLabelClasses.labelContainer}`]: {
        [`&>.${stepLabelClasses.label}`]: {
          [`&.${stepLabelClasses.alternativeLabel}`]: {
            textAlign: 'left'
          }
        }
      }
    }
  }
}
